<template>
  <el-select
    v-bind="$attrs"
    v-model="model"
    multiple
    clearable
    collapse-tags
    collapse-tags-tooltip
    :max-collapse-tags="props.maxCollapseTags"
    class="elv-base-group-multiple-choice-select"
    popper-class="elv-base-group-multiple-choice-select-popper"
    :popper-append-to-body="false"
    style="width: 100%"
    @clear="onClearSelected"
  >
    <template v-for="(item, index) in groupOption" :key="index">
      <el-checkbox
        v-model="groupSelectData[`checkAll${item.type}`]"
        class="elv-base-group-multiple-choice-select__all"
        :indeterminate="groupSelectData[`isIndeterminate${item?.type}`]"
        @change="onCheckGroupClassifyAllChange(item?.type, index, $event as boolean)"
        >{{ transformI18n(item?.label) }}</el-checkbox
      >
      <el-checkbox-group v-model="model">
        <el-option v-for="i in item?.list" :key="i.value" :label="transformI18n(i?.label)" :value="i?.value">
          <el-checkbox style="pointer-events: none" :value="i?.value">{{ transformI18n(i?.label) }}</el-checkbox>
        </el-option>
      </el-checkbox-group>
    </template>
    <template v-if="props.prefixText" #prefix>
      <span>{{ props.prefixText }}</span>
    </template>
  </el-select>
</template>
<script setup lang="ts">
import { transformI18n } from '@/i18n/index'
import { isEmpty, capitalize } from 'lodash-es'

interface OptionType {
  value: string
  label: string
  icon?: string
}

interface GroupOptionType {
  type: string
  value: string
  label: string
  list: OptionType[]
}

const props = defineProps({
  prefixText: {
    type: String,
    default: ''
  },
  groupOption: {
    type: Array as () => GroupOptionType[],
    default: () => []
  },
  width: {
    type: String,
    default: 'fit-content'
  },
  maxCollapseTags: {
    type: Number,
    default: 2
  },
  modelType: {
    type: String,
    required: true
  },
  splitString: {
    type: String,
    required: true
  }
})

const groupSelectData: any = ref({
  lastTimeData: []
})

const model = defineModel<any>({ required: true })

/**
 * @description: 分组全选/取消全选
 * @param {string} type
 * @param {number} index
 * @param {boolean} val
 */
const onCheckGroupClassifyAllChange = (type: string, index: number, val: boolean) => {
  groupSelectData.value[`isIndeterminate${type}`] = false
  if (val) {
    model.value.push(...props.groupOption[index].list.map((item: any) => item.value))
    model.value = Array.from(new Set(model.value))
  } else {
    model.value = model.value.filter(
      (item: any) => !props.groupOption[index].list.map((i: any) => i.value).includes(item)
    )
  }
  groupSelectData.value.lastTimeData = model.value
}

/**
 * @description: 修改分组选中数据
 * @param {string} key
 * @param {*} value
 */
const onChangeGroupSelectData = (key: string, value: any) => {
  groupSelectData.value[key] = value
}

/**
 * @description: 清空选中
 */
const onClearSelected = () => {
  groupSelectData.value = props.groupOption.reduce((acc: any, item: any) => {
    // eslint-disable-next-line no-param-reassign
    acc[`checkAll${item.type}`] = false
    // eslint-disable-next-line no-param-reassign
    acc[`isIndeterminate${item.type}`] = false
    return acc
  }, groupSelectData.value)
  groupSelectData.value.lastTimeData = []
}

// eslint-disable-next-line no-unused-vars
const attrs: any = useAttrs()

defineExpose({
  groupSelectData,
  onChangeGroupSelectData
})

watch(
  () => props.groupOption,
  () => {
    if (isEmpty(props.groupOption)) {
      groupSelectData.value.lastTimeData = []
    } else {
      groupSelectData.value = props.groupOption.reduce((acc: any, item: any) => {
        // eslint-disable-next-line no-param-reassign
        acc[`checkAll${item.type}`] = false
        // eslint-disable-next-line no-param-reassign
        acc[`isIndeterminate${item.type}`] = false
        return acc
      }, groupSelectData.value)
      groupSelectData.value.lastTimeData = []
    }
  },
  { immediate: true }
)

watch(
  () => props.modelType,
  () => {
    if (model.value.length) {
      groupSelectData.value.lastTimeData = model.value
      model.value.forEach((item: any) => {
        const type = capitalize(item.split(props.splitString)[0])
        const typeDataLength = model.value?.filter((i: any) => capitalize(i.split('_')[0]) === type).length ?? 0
        groupSelectData.value[`checkAll${type}`] =
          props.groupOption.find((i: any) => i.type === type)?.list.length === typeDataLength
        groupSelectData.value[`isIndeterminate${type}`] = !!(
          (typeDataLength > 0 &&
            (model.value ?? []).filter((i: any) => capitalize(i.split(props.splitString)[0]) === type).length <
              (props.groupOption.find((i: any) => i.type === type)?.list.length ?? 0)) ||
          false
        )
      })
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-base-group-multiple-choice-select {
  display: flex;
  align-items: center;

  &.el-select {
    width: fit-content;

    .el-select__wrapper {
      width: v-bind('props.width');

      .el-select__selection {
        width: fit-content;

        &.is-near {
          margin-left: 0px;
        }
      }

      .el-select__prefix {
        img {
          width: 16px;
          height: 16px;
          display: block;
        }
      }

      .el-tag {
        border-radius: 3px;
        border: 1px solid #dde1e6;
        background: #edf0f3;
        display: flex;
        padding: 0px 4px 0px 8px;
        justify-content: center;
        align-items: center;
        gap: 2px;
        box-sizing: border-box;
        width: fit-content;

        .el-tag__content {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }

        .el-icon.el-tag__close {
          width: 12px;
          height: 12px;
          color: #aaafb6;
          margin-left: 2px;

          &:hover {
            color: #fff;
            background-color: #909399;
          }
        }
      }

      .el-select__suffix {
        .el-icon {
          margin-left: 4px;

          svg {
            width: 12px;
            height: 12px;
          }

          .el-select__icon {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}

.elv-base-group-multiple-choice-select-popper {
  &.el-popper {
    transform: translateY(-11.5px);

    .elv-base-group-multiple-choice-select__all {
      width: 100%;
      box-sizing: border-box;
      padding-left: 8px;
      margin-right: 0px;
      border-top: 1px solid #edf0f3;
      border-bottom: 1px solid #edf0f3;

      &:first-of-type {
        border-top: 0px;
      }

      .el-checkbox__label {
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .el-checkbox-group {
      .el-checkbox {
        .el-checkbox__label {
          display: flex;
          align-items: center;

          img {
            width: 18px;
            height: 18px;
            display: block;
            border-radius: 50%;
            margin-right: 8px;
          }
        }
      }
    }

    .el-select-dropdown__list {
      padding: 0;
    }

    .is-multiple {
      .el-select-dropdown__item {
        padding-left: 8px;

        &.selected::after,
        &.is-selected::after {
          width: 0;
          height: 0;
        }
      }
    }
  }
}
</style>
